<template>
<div>
  <Row :gutter="10">
    <Col span="4">
      <Input v-model="searchKey.title" placeholder="标题" clearable/>
    </Col>
    <Col span="2">
      <Button type="primary" @click="search()">查询</Button>
    </Col>
    <Col span="18" style="text-align: right">
      <Button type="primary" @click="add()">新增</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
        <template slot="action" slot-scope="{row}">
          <Button style="margin-left: 5px" type="primary" @click="mod(row.news_id)">修改</Button>
          <Button style="margin-left: 5px" type="error" @click="del(row.news_id)">删除</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
export default {
  name: "News",

  data: function(){
    return {
      searchKey: {
        title: '',
        page: 1,
        pagesize: 30
      },
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '标题', key: 'news_title'},
        {title: '发布时间', key: 'news_date', width: 120},
        {title: '操作', slot: 'action', width: 180}
      ],
      list: [],
      total: 0
    }
  },
  methods: {
    getList: function(){
      this.$http.get('backend/news/list', {params: this.searchKey}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    search: function(){
      this.searchKey.page = 1
      this.getList()
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    add: function(){
      this.$router.push('/xiaochengxu/news/add')
    },
    mod: function(id){
      this.$router.push({path: '/xiaochengxu/news/update', query: {id: id}})
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确定要删除这一条吗？</p>',
        onOk: () => {
          this.$http.delete('backend/news/delete', {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.getList();
              this.$Message.success('删除成功')
            } else {
              this.$Message.error(response.data.err);
              console.log(response)
            }
          })
        }
      });
    }
  },
  mounted() {
    this.getList()
  }
}

</script>

<style scoped>

</style>